<template>
  <div>
    <div class="sub-title">
      {{ $t("选择支付网络") }}
      <FA @grandchild-event="childEvt"></FA>
    </div>

    <div class="bank-list">
      <div class="bank-item" v-for="item,index in allUsdt" @click="tabItem(item)" :key="index">
        <div class="hot-icon-box" v-if="item.is_hot==1">
          <img src="@/assets/images/hot-icon.png" class="hot-icon">
        </div>
        <div class="item-content">
          <div class="content-title ell" :title="item.pay_name || item.bank_name ">
            <img :src="item.icon" alt="" class="item-icon">
            <span>{{ item.pay_name || item.bank_name }}</span>
          </div>
          <div class="content-num ell" :title="contentNum(item)">{{ contentNum(item) }}</div>
        </div>
        <div class="bank-item-icon-wapper" v-if="false">
          <img :src="item.src" alt="" class="bank-item-icon" v-for="item,index in bankAll.bank_payment_system " :key="index">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myTabs from "@/components/myTabs.vue";
import VueQr from "vue-qr";
import FA from "./FA.vue";
export default {
  data() {
    return {
      value: "",
    };
  },
  props:{
    // 数字钱包列表
    digitalWalletList:{
      default:()=>[]
    },
    allUsdt:{
      default:()=>[]
    },
    dep_address:{
      default:()=>""
    }
  },
  watch:{
    digitalWalletList:{
      handler(){
        this.value = this.digitalWalletList[0].name
      },
      deep:true,
      immediate: true,
    }
  },
  components: { VueQr, FA, myTabs },
  methods:{
    /**
     * 格式化最小-最大充值
     * @param {Object} item 列表循环的item
     * */ 
     contentNum(item){
      return `${item.currencyApi.currency_symbol }${this.numFormat(item.pay_min_amout || item.min_amcount )}-${ item.currencyApi.currency_symbol }${ this.numFormat(item.pay_max_amout || item.max_amcount) }`
    },
    /**
     * 卡片点击事件，点击进行充值下一步
     * @param {Object} item 列表循环的item
     * */ 
     tabItem(item){
      this.$emit("submit",item)
    },
    childEvt(value) {
      this.$emit('parent-event', value);
    }
  }
};
</script>

<style scoped lang="less">
.select {
  width: 100%;
  margin-bottom: 24px;
}
.sel-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.prefix-icon {
  width: 20px;
  height: 20px;
  transform: translate(-5px, 10px);
}
.sub-title {
  font-size: 14px;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #AED9CC;
}
.bank-item{
  width: 236px;
  /* aspect-ratio: 1.59; */
  padding-bottom: 20%;
  background-image: url(../../assets/images/bank-bg-new.png);
  background-size: 100% 100%;
  position: relative;
  margin-bottom: 14px;
  cursor: pointer;
  color: #FFF;
  font-weight: bold;
}
.item-content{
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  font-size: 12px;
  left: 10%;
  right: 10%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.content-time{
  transform: scale(.8);
  transform-origin:0 0 ;
}
.ell{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bank-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 25px;
}
.more{
  text-align: center;
  color: #2774E8;
  font-size: 12px;
  display: block;
}
.more span{
  cursor: pointer;
}
.item-icon{
  width: 1.8em;
  border-radius: 20%;
  margin-right: .6em;
}
.bank-item-icon-wapper{
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 10px;
}
.bank-item-icon-wapper img{
  width: 16.66%;
  flex: 1;
}
.hot-icon-box{
  width: 16%;
  position: absolute;
  right: 4%;
  top: 0;
  background: linear-gradient( 180deg, #FFFFB7 0%, #54EB8C 100%);
  border-radius: 0px 0px 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
}
.hot-icon{
  width: 40%;
}
.content-title{
  margin-bottom: 5%;
  font-size: 14px;
  display: flex;
  align-items: center
}
@media screen and (max-width:555px) {
  .item-content{
    font-size: 11px;
  }
  .content-title{
    font-size: 12px;
  }
  .bank-item{
    width:calc(50% - 5px);
    margin-bottom: 12px;
  }
  .sub-title{
    margin-bottom: 9px;
  }
  .bank-list{
    margin-bottom: 25px;
  }
  .content-time{
    width: 130%;
  }
}
</style>
