import { render, staticRenderFns } from "./digitalCurrencyRecharge.vue?vue&type=template&id=8588040c&scoped=true&"
import script from "./digitalCurrencyRecharge.vue?vue&type=script&lang=js&"
export * from "./digitalCurrencyRecharge.vue?vue&type=script&lang=js&"
import style0 from "./digitalCurrencyRecharge.vue?vue&type=style&index=0&id=8588040c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8588040c",
  null
  
)

export default component.exports